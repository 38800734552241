/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-return */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-unsafe-optional-chaining */
import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Col, Form, message, Modal, Row, Spin } from 'antd';
import { Scheduler } from 'devextreme-react';
import { AppointmentDragging, Resource } from 'devextreme-react/scheduler';
import moment from 'moment';
import { renderToStaticMarkup } from 'react-dom/server';
import { useLocation, useParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import { isArray } from '@craco/craco/lib/utils';

import Utils from '../../Assets/Scripts/Utils';
import EmailConfirmPopup from '../../Components/EmailConfirmPopup/EmailConfirmPopup';
import OccurrencesPopup from '../../Components/OccurrencePopup/OccurrencePopup';
import QuoteList from '../../Components/QuoteList/QuoteList';
import ReasonModal from '../../Components/ReasonModal/ReasonModal';
import Appointment from '../../Components/Schedule/Appointment';
import AppointmentTooltipComponent from '../../Components/Schedule/AppointmentTooltip';
import DataCell from '../../Components/Schedule/DataCell';
import DateCell from '../../Components/Schedule/DateCell';
import InstructorBookingPopup from '../../Components/Schedule/InstructorBooking/InstructorBookingPopup';
import ModalConfirmBody from '../../Components/Schedule/ModalConfirmBody';
import ModalUploadFile from '../../Components/Schedule/ModalUploadFile';
import NewSlotPopup from '../../Components/Schedule/NewSlotPopup';
import OverviewPopup from '../../Components/Schedule/OverviewPopup';
import ResourceCellRender from '../../Components/Schedule/ResourceCellRender';
import ResumePopup from '../../Components/Schedule/ResumePopup';
import ScheduleUtils from '../../Components/Schedule/ScheduleUtils';
import TaskCommentFunctions from '../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../Services/axiosService';
import UserFunctions from '../User/Register/UserFunctions';

import { fetchTaskById } from './API/ScheduleSchedulingAPI';
import SchedulingFunctions from './SchedulingFunctions';

import './ScheduleScheduling.scss';

let scheduleDate = moment(new Date()).format('YYYY-MM-DD');
let currentCTName = '';
let taskAcceptanceDeadline = 0;

let currentOpenTask = null;
let confirmationData = null;
let event = '';
let onModalOk = null;
let firstOpen = true;
// let trainingOpened = false;

const views = [
  {
    name: 'Dia',
    type: 'day',
    cellDuration: 60,
  },
];

const notifyDisabledDate = () => {
  message.warning('Não é possível criar ou mover um treinamento para uma data desabilitada.');
};

const notifyDisableDelete = () => {
  message.warning('Não é possível remover um agendamento neste estágio!');
};

const notifyWrongTrainingType = (trainingType) => {
  message.warning(`Este recurso não aceita este Tipo de Treinamento (${trainingType})!`);
};

const notifySameInstructors = () => {
  message.warning('O "Instrutor" e o "Instrutor Auxiliar" não podem ser o mesmo!');
};

const notifyRetroactiveDay = () => {
  message.warning('Não é possível agendar treinamentos em dias retroativos!');
};

const notifyExceededCapacity = (students, capacity) => {
  message.warning(`Capacidade de alunos por dia excedida (${students}/${capacity})!`);
};

function ScheduleSchedulingComponent() {
  // eslint-disable-next-line radix
  const dealId = window.location.search.split('=')[1];
  const draggingGroupName = 'appointmentsGroup';
  const groups = ['resourceId'];

  const schedulerRef = useRef(null);
  const newSlotPopupRef = useRef(null);
  const overviewPopupRef = useRef(null);
  const resumePopupRef = useRef(null);
  const instructorBookingPopupRef = useRef(null);

  const urlParams = useParams();
  const location = useLocation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [scheduleParams, setScheduleParams] = useState();
  const [appointments, setAppointments] = useState([]);
  const [trainingCenter, setTrainingCenter] = useState();
  const [quoteList, setQuoteList] = useState();
  const [instructorOptions, setInstructorOptions] = useState();
  const [driverOptions, setDriverOptions] = useState();
  const [transportOptions, setTransportOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [blockedDates, setBlockedDates] = useState([]);
  const [instructorsBlockedDates, setInstructorsBlockedDates] = useState([]);
  const [slots, setSlots] = useState([]);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [stages, setStages] = useState();
  const [ctResources, setCtResources] = useState();
  const [ctResourcesSlots, setCtResourcesSlots] = useState();
  const [isReasonModalOpen, setReasonModalOpen] = useState(false);
  const [isUploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [reasonsOptions, setReasonsOptions] = useState();
  const [forceRender, setForceRender] = useState(false);
  const [usedCapacity, setUsedCapacity] = useState(0);
  const [bitrixProducts, setBitrixProducts] = useState([]);
  const [overview, setOverview] = useState([]);
  const [resume, setResume] = useState([]);
  const [bitrixUsers, setBitrixUsers] = useState([]);
  const [uploadFileType, setUploadFileType] = useState('');
  const [openTraining, setOpenTraining] = useState();
  const [reasonNoBillingOptions, setReasonNoBillingOptions] = useState([]);
  const [trainingCenterOptions, setTrainingCenterOptions] = useState();
  const [taskRescheduling, setTaskRescheduling] = useState(null);
  const [occurrencesPopupVisible, setOccurrencesPopupVisible] = useState(false);
  const [emailConfirmPopupVisible, setEmailConfirmPopupVisible] = useState(false);
  const [confirmEmailType, setConfirmEmailType] = useState();

  const openEmailConfirmPopup = (type) => {
    setConfirmEmailType(type);
    setEmailConfirmPopupVisible(true);
  };

  const updateOverviewAndResume = () => {
    const currentStartDate = schedulerRef.current.instance.getStartViewDate();
    const currentEndDate = schedulerRef.current.instance.getStartViewDate();
    const startDate = moment(currentStartDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
    const endDate = moment(currentEndDate.setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');
    SchedulingFunctions.getOverviewData(appointments, setOverview, startDate, endDate);
    SchedulingFunctions.getResumeData(appointments, setResume, startDate, endDate);
  };

  const logisticsTrainingHandler = async (e, type) => {
    try {
      const data = e;
      data.endDate = new Date(data.endDate);
      data.startDate = new Date(data.startDate);
      const oldTaskData = JSON.parse(JSON.stringify(data));

      let stageId = null;
      switch (type) {
        case 'deny':
          stageId = 4; // 3 = Estágio "Negado"
          break;
        case 'noClientContact':
          stageId = 13; // 13 = Estágio "Reservado Sem Confirmação"
          break;
        default:
          break;
      }

      data.stage = stages.find(({ id }) => id === stageId);

      setLoadingSchedule(true);
      await api.put('/Task/UpdateTaskScheduling', data).then(() => {
        message.success('Treinamento Confirmado!');
      });

      await TaskCommentFunctions.addTaskCommentActivities(data, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentHistory(data, oldTaskData, 'Scheduling');

      const quote = quoteList?.find(({ id }) => id === data.dealId);
      const task = quote?.tasks?.find(({ id }) => id === data.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = data.stage;
        quote.tasks.splice(indexTask, 1, task);
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }
      setLoadingSchedule(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao confirmar o treinamento!');
    }
  };

  const logisticsDenyHandler = async (e) => {
    const data = e;

    onModalOk = async () => logisticsTrainingHandler(e, 'deny');

    event = 'LogisticsDeny';
    currentOpenTask = data;
    setReasonModalOpen(true);
  };

  const schedulingType = async (e, oldTaskData, startDate) => {
    try {
      const data = e;
      const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));

      if (data.acceptTerm2 && data.acceptTerm2 !== '') {
        data.stage = stages.find(({ id }) => id === 12); // 12 = Estágio "Reservado"
      } else {
        data.stage = stages.find(({ id }) => id === 10); // 10 = Estágio "Pré-Reservado"
      }

      data.trainingCenter = trainingCenter?.commercialName ?? scheduleData.trainingCenter;
      data.trainingCenterId = trainingCenter?.id ?? scheduleData.trainingCenterId;

      setLoadingSchedule(true);
      await api.put('/Task/UpdateTaskScheduling', data).then(() => {
        appointments.push(data);
        setAppointments([...appointments.map((appointment, index) => ({ ...appointment, index }))]);
        message.success('Treinamento Agendado!');
      });

      await TaskCommentFunctions.addTaskCommentActivities(data, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentHistory(data, oldTaskData, 'Scheduling');

      // Envia Notificação ao CT
      await ScheduleUtils.sendPushNotification(data, 'TaskReceived', 'CT', data.trainingCenterId);

      // Atualiza Overview e Resumo
      updateOverviewAndResume();

      // Atualiza as informações em tela
      const quote = quoteList?.find(({ id }) => id === data.dealId);
      const task = quote?.tasks?.find(({ id }) => id === data.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = data.stage;
        task.startDateFormatted = moment(startDate).format('DD/MM/YYYY');
        quote.tasks.splice(indexTask, 1, task);
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }
      setTaskRescheduling(null);
      setLoadingSchedule(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao reservar o treinamento!');
    }
  };

  const formatAppointmmentInfo = (e, data) => {
    const resource = ctResources?.find(({ id }) => id === data.resourceId);

    data.resource = resource.trainingType;
    data.resourceId = resource.id;
    data.startDate = new Date(moment(data.startDate).add(1, 'seconds'));
    data.acceptanceDeadline = new Date(moment(data.startDate).add(taskAcceptanceDeadline, 'hours'));

    const dateKey = moment(data.startDate).format('YYYY-MM-DD');

    // eslint-disable-next-line array-callback-return, consistent-return
    const resourceSlot = ctResourcesSlots[dateKey][resource?.id]?.find((resource) => {
      const startDt = new Date(data.startDate);
      const endDt = new Date(data.endDate);
      const slotStartDt = new Date(resource.slotStart);
      const slotEndDt = new Date(resource.slotEnd);

      const slotStartHour = Utils.createSafeDate(startDt, slotStartDt);
      const slotEndHour = Utils.createSafeDate(endDt, slotEndDt);

      if (startDt >= slotStartHour && startDt <= slotEndHour) {
        return resource;
      }
    });

    if (!resourceSlot?.isValid) {
      const errMessage = 'Oops! Slot inválido, contate um administrador do sistema.';
      Utils.logError(errMessage);
      message.error(errMessage);
      e.cancel = true;
      return true;
    }

    const slotStart = moment(resourceSlot.slotStart);
    const slotStartDate = slotStart
      .set('year', data.startDate.getFullYear())
      .set('month', data.startDate.getMonth())
      .set('date', data.startDate.getDate());

    const slotStartFormatted = slotStartDate.toDate();

    const slotTotalTime = 2;
    const slotEndFormatted = slotStartDate
      .clone()
      .add(slotTotalTime, 'hours')
      .subtract(2, 'seconds')
      .toDate();

    const { trainingTypeId } = ctResources?.find(({ id }) => id === data.resourceId);

    if (trainingTypeId !== data.trainingTypeId) {
      e.cancel = true;
      notifyWrongTrainingType(data.trainingType);
      return true;
    }

    data.startDate = slotStartFormatted;
    data.endDate = slotEndFormatted;
    return false;
  };

  const getProductName = (product) => {
    const customProductList = ScheduleUtils.getCustomProductList();
    const isCustomProduct = customProductList.includes(product?.id);

    if (isCustomProduct) {
      return product.complement ? product.complement : product.name;
    }

    return product.name;
  };

  const onAppointmentAdd = async (e) => {
    try {
      const data = e.itemData;
      const oldTaskData = JSON.parse(JSON.stringify(data)); // Deep copy da task

      // verifica se a data é retroativa
      if (data.startDate < new Date().setHours(0, 0, 0, 0)) {
        e.cancel = true;
        notifyRetroactiveDay();
        return;
      }

      // Realiza algumas validações e formatações
      const shouldCancel = formatAppointmmentInfo(e, data);
      if (shouldCancel) {
        return;
      }

      // Retorna os treinamentos que já estão nos slots
      const foundAppointments = appointments.filter(
        ({ startDate, endDate, resourceId }) =>
          ((new Date(data.startDate) >= new Date(startDate) &&
            new Date(data.startDate) <= new Date(endDate)) ||
            (new Date(data.endDate) >= new Date(startDate) &&
              new Date(data.endDate) <= new Date(endDate))) &&
          data.resourceId === resourceId
      );

      SchedulingFunctions.addSlotClassId(data, foundAppointments);

      const studentsScheduled = usedCapacity + data.qtyStudentsPerClass;
      const exceedsCapacity = studentsScheduled > trainingCenter.maxCapacityPerDay;

      // Excede Capacidade de Alunos por dia
      if (exceedsCapacity) {
        e.cancel = true;
        notifyExceededCapacity(studentsScheduled, trainingCenter.maxCapacityPerDay);
        return;
      }

      // Regras de Slot ocupado
      if (foundAppointments?.length > 0) {
        const [firstAppointment] = foundAppointments;
        const customProductList = ScheduleUtils.getCustomProductList();
        const isCustomProduct =
          customProductList.includes(data.product?.id) || data.group?.id === 24; // 24 = Personalizado

        if (!data.sharedTraining && !isCustomProduct) {
          e.cancel = true;
          message.warning('Este Treinamento não é compartilhado!');
          return;
        }

        if (
          !firstAppointment.sharedTraining &&
          !firstAppointment.acceptSharedTraining &&
          !isCustomProduct
        ) {
          e.cancel = true;
          message.warning('Este Slot não é compartilhado!');
          return;
        }

        if (firstAppointment.group?.id !== data.group?.id && !isCustomProduct) {
          e.cancel = true;
          message.warning('Este Slot possui um Grupo de Produto diferente!');
          return;
        }
      }

      // Data desabilitada
      const startDate = new Date(data.startDate);
      if (
        !ScheduleUtils.isValidAppointmentDate(startDate, trainingCenter?.workDays) ||
        ScheduleUtils.isBlockedDay(startDate, blockedDates)
      ) {
        e.cancel = true;
        notifyDisabledDate();
        return;
      }

      Modal.confirm({
        title: 'Deseja realmente reservar essa data?',
        content: <ModalConfirmBody itemData={data} />,
        okText: 'Não',
        cancelText: 'Sim',
        centered: true,
        style: { alignItems: 'center' },
        icon: null,
        cancelButtonProps: {
          type: 'primary',
          style: {
            backgroundColor: '#5cb85c',
            borderColor: 'transparent',
            color: '#fff',
            width: '40%',
          },
        },
        okButtonProps: {
          type: 'default',
          style: {
            width: '40%',
          },
        },
        onCancel: () => {
          data.startHour = form.getFieldValue('startHour');
          data.endHour = form.getFieldValue('endHour');
          data.dateScheduling = moment().format('YYYY-MM-DDTHH:mm:ss');

          schedulingType(data, oldTaskData, startDate);

          form.setFieldsValue([]);
        },
        keyboard: false,
      });
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao reservar o treinamento!');
    }
  };

  const removeQuoteOrDealUpdatedBadge = (taskToUpdate) => {
    api
      .put(`/Task/RemoveQuoteOrDealUpdatedBadge?taskId=${taskToUpdate.id}`)
      .then((res) => res)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });
  };

  const onAppointmentUpdating = async (e) => {
    try {
      const newTaskData = e.newData;
      e.cancel = true;
      const oldTaskData = JSON.parse(JSON.stringify(currentOpenTask));

      // Se for update através do botão "Confirmar"
      if (confirmationData) {
        newTaskData.confirmedBy = confirmationData.confirmedBy;
        newTaskData.idConfirmedBy = confirmationData.idConfirmedBy;
        newTaskData.confirmationDate = confirmationData.confirmationDate;
        newTaskData.stage.id = confirmationData.newStageId;
      }

      const shouldCancel = formatAppointmmentInfo(e, newTaskData);
      if (shouldCancel) {
        return;
      }

      if (
        newTaskData.mainInstructor &&
        newTaskData.assistantInstructor &&
        newTaskData.mainInstructor?.userId === newTaskData.assistantInstructor?.userId
      ) {
        notifySameInstructors();
        return;
      }

      // Manipula os dados dos Operadores (Instrutor, Instrutor Auxiliar, Motorista, Veículo)
      SchedulingFunctions.mapSubmitData(
        newTaskData,
        stages,
        instructorOptions,
        driverOptions,
        vehicleOptions,
        transportOptions,
        reasonNoBillingOptions,
        trainingCenterOptions,
        taskAcceptanceDeadline
      );

      let newStageId = newTaskData.stage.id;
      if (newTaskData.hasDocumentation) {
        newStageId = 5;
      }

      const newStage = stages?.find(({ id }) => id === newStageId);
      const newStageDp = JSON.parse(JSON.stringify(newStage));
      newTaskData.stage = newStageDp;

      setLoadingSchedule(true);
      await api.put('/Task/UpdateTaskScheduling', newTaskData).then(() => {
        message.success('Treinamento atualizado!');
      });

      // Gera Atividade conforme mudança de valores dos campos
      await TaskCommentFunctions.addTaskCommentActivities(newTaskData, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentHistory(newTaskData, oldTaskData, 'Scheduling');
      await TaskCommentFunctions.addTaskCommentDriver(newTaskData, oldTaskData, driverOptions);
      await TaskCommentFunctions.addTaskCommentVehicle(newTaskData, oldTaskData, vehicleOptions);

      // Enviar Notificação para os Operadores (Instrutor, Instrutor Auxiliar, Motorista)
      await SchedulingFunctions.sendNotificationToOperators(newTaskData, oldTaskData);

      // Atualiza a task em tela sem precisar fazer o get denovo
      const quote = quoteList?.find(({ id }) => id === newTaskData.dealId);
      const task = quote?.tasks?.find(({ id }) => id === newTaskData.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = newTaskData.stage;
        quote.tasks.splice(indexTask, 1, { ...task, ...newTaskData });
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }

      const appointment = appointments.find(({ id }) => id === newTaskData.id);
      const indexAppointment = appointments.indexOf(appointment);
      appointments.splice(indexAppointment, 1, newTaskData);
      setAppointments([...appointments]);
      setLoadingSchedule(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao atualizar o treinamento!');
    }
  };

  const onAppointmentFormOpening = async (e) => {
    const { form, popup } = e;

    // Loader para limpar o form original e exibir carregamento enquanto busca as Atividades
    const loader = [
      {
        template: () =>
          renderToStaticMarkup(
            <Spin
              style={{ width: 'calc(200% + 30px)' }}
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 48,
                  }}
                  spin
                />
              }
            />
          ),
        label: {
          text: ' ',
        },
      },
    ];

    form.option('items', [
      {
        colCountByScreen: {
          lg: 1,
        },
        colSpan: 1,
        itemType: 'group',
        name: 'mainGroup',
        items: loader,
      },
    ]);

    const [newTask] = await fetchTaskById(e.appointmentData.id);
    const openTask = JSON.parse(JSON.stringify(newTask)); // Deep Copy da task
    currentOpenTask = openTask;
    confirmationData = null;
    const { isReadOnly = false } = newTask;

    if (isReadOnly) {
      form.option('readOnly', true);
    }

    // Envia request para remover a marcação de Treinamento "Atualizado"
    if (newTask.quoteOrDealUpdated) {
      removeQuoteOrDealUpdatedBadge(newTask);
    }

    // Remove a marcação de Treinamento "Atualizado" da tela
    popup._actions.onHiding = () => {
      const taskQuote = quoteList
        ?.find(({ id }) => id === newTask.dealId)
        ?.tasks?.find(({ id }) => id === newTask.id);
      const taskAppointment = appointments?.find(({ id }) => id === newTask.id);
      if (newTask.quoteOrDealUpdated) {
        if (taskQuote) {
          taskQuote.quoteOrDealUpdated = false;
        }
        if (taskAppointment) {
          taskAppointment.quoteOrDealUpdated = false;
        }
        setForceRender(!forceRender);
      }
    };

    if (isArray(newTask?.quoteProducts)) {
      let concatenatedProducts = '';
      newTask?.quoteProducts?.forEach((product) => {
        concatenatedProducts += `${product.name}\n`;
      });
      newTask.quoteProducts = concatenatedProducts;
    }

    // Titulo Popup
    popup.option('showTitle', true);
    popup.option('title', newTask.cardTitle);
    popup.option('hideOnOutsideClick', false);
    popup.option('showCloseButton', true);

    // Botões Popup
    let toolbar = popup.option('toolbarItems');

    toolbar.push({
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'Modelo Lista de Presença',
        onClick: () => {
          window.open(
            'https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/modelo-lista-presenca.xlsx?alt=media&token=f72f1017-54b9-42b5-b4f4-d4c6839cb23f',
            '_blank'
          );
        },
      },
      shortcut: 'cancel',
    });

    toolbar[0].options.text = 'Salvar';
    toolbar[0].options.type = 'success';
    toolbar[0].options.disabled = isReadOnly;
    toolbar[0].location = 'center';
    const saveButton = JSON.parse(JSON.stringify(toolbar[0]));
    saveButton.onClick = toolbar[0]?.onClick;

    const confirmButton = {
      location: 'center',
      onClick: (b) => {
        const userData = JSON.parse(localStorage.getItem('conecta__userData'));

        confirmationData = {
          confirmedBy: userData.name,
          idConfirmedBy: parseInt(userData.id, 10),
          confirmationDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          newStageId: 3,
        };

        toolbar[0]?.onClick(b);
      },
      options: {
        text: 'Confirmar',
        type: 'success',
        disabled: isReadOnly,
      },
      shortcut: 'cancel',
    };

    if (
      newTask.stage.id === 12 &&
      (fieldsPermissions?.LogisticConfirm?.access >= 1 ||
        fieldsPermissions?.LogisticDeny?.access >= 1 ||
        fieldsPermissions?.LogisticNoClientContact?.access >= 1)
    ) {
      toolbar = [];
      toolbar.push(saveButton);

      if (fieldsPermissions?.LogisticConfirm?.access >= 1) {
        toolbar.push(confirmButton);
      }
      if (fieldsPermissions?.LogisticDeny?.access >= 1) {
        toolbar.push({
          location: 'center',
          onClick: () => logisticsDenyHandler(newTask),
          options: {
            text: 'Negar',
            type: 'danger',
            disabled: isReadOnly,
          },
          shortcut: 'cancel',
        });
      }
      if (fieldsPermissions?.LogisticNoClientContact?.access >= 1) {
        toolbar.push({
          location: 'center',
          onClick: () => logisticsTrainingHandler(newTask, 'noClientContact'),
          options: {
            text: 'Sem contato com cliente',
            disabled: isReadOnly,
          },
          shortcut: 'cancel',
        });
      }
    } else {
      toolbar[1].options = {};
      toolbar[1].location = 'center';
      toolbar[1].options.text = 'Cancelar';
    }

    SchedulingFunctions.addDealLink(toolbar, e);
    SchedulingFunctions.addOccurrencesBtn(
      toolbar,
      setOccurrencesPopupVisible,
      fieldsPermissions,
      isReadOnly
    );
    SchedulingFunctions.addEmailConfirmDropdown(
      toolbar,
      e,
      openEmailConfirmPopup,
      fieldsPermissions,
      isReadOnly
    );
    SchedulingFunctions.addInfoBtn(toolbar, e);

    popup.option('toolbarItems', toolbar);

    const commentsTask = await api
      .get(
        `/TaskComment?filters[0].Field=TaskId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${newTask.id}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as Atividades!');
      });

    const commentsQuote = await api
      .get(
        `/TaskComment?filters[0].Field=DealId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${newTask.dealId}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as Atividades!');
      });

    const comments = [...commentsTask, ...commentsQuote]?.sort((a, b) => a.id - b.id);

    const availableInstructors = await SchedulingFunctions.fetchInstructorsAvailability(
      instructorOptions,
      openTask.startDate,
      openTask.endDate
    );

    let combinedInstructorList = [];
    if (availableInstructors?.length > 0) {
      combinedInstructorList = instructorOptions?.map((instructor) => {
        const availability = availableInstructors.find((avail) => avail.id === instructor.id);
        const trainingGroupsList = instructor?.trainingKind?.map(({ id }) => id);

        return {
          label: instructor.name,
          value: instructor.id,
          hasTrainings: availability?.hasTrainings ?? false,
          isBlocked: availability?.isBlocked ?? false,
          disabled: availability?.isBlocked ?? false,
          trainingGroupsList,
        };
      });
    }

    // Gera os campos do Form
    const generalItems = ScheduleUtils.generateGeneralFormFields(
      e,
      fieldsPermissions,
      stages,
      trainingsOptions,
      ctResources,
      setUploadFileModalOpen,
      setUploadFileType,
      combinedInstructorList,
      onAppointmentUpdating
    );

    const formGroups = [];
    formGroups.push({
      colCountByScreen: {
        lg: 1,
      },
      colSpan: 1,
      itemType: 'group',
      name: 'mainGroup',
      caption: 'Geral',
      items: generalItems,
    });

    formGroups.push({
      itemType: 'tabbed',
      colSpan: 1,
      tabPanelOptions: {
        deferRendering: false,
        height: '100%', // Adjust the height as needed
        tabsPosition: 'right', // Position of the tabs
      },
      tabs: [
        {
          title: 'Atividade Resumida',
          items: TaskCommentFunctions.generateTaskCommentsFields(
            comments,
            fieldsPermissions,
            'activity'
          ),
          cssClass: 'custom-tab', // Apply custom class to the tab
        },
        {
          title: 'Atividade Completa',
          items: TaskCommentFunctions.generateTaskCommentsFields(
            comments,
            fieldsPermissions,
            'all'
          ),
          cssClass: 'custom-tab', // Apply custom class to the tab
        },
      ],
    });

    if (ScheduleUtils.checkLogisticFormFieldsAccess(fieldsPermissions)) {
      formGroups.push({
        colCountByScreen: {
          lg: 1,
        },
        colSpan: 1,
        itemType: 'group',
        name: 'logisticsInformation',
        caption: 'Logística',
        items: ScheduleUtils.generateLogisticsFormFields(
          e,
          form,
          fieldsPermissions,
          onAppointmentUpdating,
          setUploadFileType,
          setUploadFileModalOpen
        ),
      });
    }

    if (ScheduleUtils.checkFinanceFormFieldsAccess(fieldsPermissions)) {
      formGroups.push({
        colCountByScreen: {
          lg: 1,
        },
        colSpan: 1,
        itemType: 'group',
        name: 'financeInformation',
        caption: 'Financeiro',
        items: ScheduleUtils.generateFinanceFormFields(
          e,
          fieldsPermissions,
          onAppointmentUpdating,
          setUploadFileType,
          setUploadFileModalOpen,
          reasonNoBillingOptions,
          trainingCenterOptions
        ),
      });
    }

    if (ScheduleUtils.checkTransportFormFieldsAccess(fieldsPermissions) && newTask.showTransport) {
      formGroups.push({
        colCountByScreen: {
          lg: 1,
        },
        colSpan: 1,
        itemType: 'group',
        name: 'transportInformation',
        caption: 'Transporte',
        items: ScheduleUtils.generateTransportFormFields(
          e,
          form,
          fieldsPermissions,
          vehicleOptions,
          driverOptions,
          transportOptions
        ),
      });
    }
    form.option('items', formGroups);
  };

  const onAppointmentDeleting = async (e) => {
    e.cancel = true;
    const data = JSON.parse(JSON.stringify(e.appointmentData)); // Deep Copy da task

    // 5 = Concluido / 9 = Aguardando Conclusão / 14 = Aguardando Documentação
    if (data.stage.id === 5 || data.stage.id === 9 || data.stage.id === 14) {
      notifyDisableDelete();
      return;
    }

    onModalOk = async (stageId) => {
      setLoadingSchedule(true);
      const newStage = stages?.find(({ id }) => id === stageId);

      await api
        .delete(`/Task?taskId=${data.id}&stageId=${stageId}`)
        .then(() => {
          message.success('Treinamento Removido!');
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Algo deu errado ao tentar remover o Treinamento!');
        });

      appointments.splice(data.index, 1); // Remove da lista
      setAppointments([...appointments.map((appointment, index) => ({ ...appointment, index }))]);

      // Atualiza Overview e Resumo
      updateOverviewAndResume();

      const quote = quoteList?.find(({ id }) => id === data.dealId);
      const task = quote?.tasks?.find(({ id }) => id === data.id);

      if (quote && task) {
        const indexTask = quote.tasks.indexOf(task);
        const indexQuote = quoteList.indexOf(quote);

        task.stage = newStage;
        task.startDateFormatted = null;
        quote.tasks.splice(indexTask, 1, task);
        quoteList.splice(indexQuote, 1, quote);
        setQuoteList([...quoteList]);
      }
      setLoadingSchedule(false);
    };

    event = 'TaskRemoved';
    currentOpenTask = data;
    setReasonModalOpen(true);
  };

  const renderDataCell = (itemData) => (
    <DataCell
      itemData={itemData}
      workDays={trainingCenter?.workDays}
      blockedDates={blockedDates}
      ctResourcesSlots={ctResourcesSlots}
      appointments={appointments}
      showResourceCapacity
    />
  );

  const renderDateCell = (itemData) => (
    <DateCell itemData={itemData} workDays={trainingCenter?.workDays} blockedDates={blockedDates} />
  );

  const removeAppointmentByIndex = (index) => {
    appointments.splice(index, 1);
    setAppointments([...appointments]);
  };

  const renderAppointmentTooltip = (itemData) => (
    <AppointmentTooltipComponent
      key={itemData.id}
      itemData={itemData}
      reasonsOptions={reasonsOptions}
      schedulerRef={schedulerRef}
      setTaskRescheduling={setTaskRescheduling}
      removeAppointmentByIndex={removeAppointmentByIndex}
    />
  );

  const resourceCellRender = (itemData) => <ResourceCellRender itemData={itemData} />;

  const appointmentRender = (itemData) => <Appointment itemData={itemData} />;

  const fetchQuotes = async (dealIds) => {
    const batchSize = 30;
    const results = [];

    if (dealIds && dealIds.length > 0) {
      for (let i = 0; i < dealIds.length; i += batchSize) {
        const batch = dealIds.slice(i, i + batchSize);

        try {
          const response = await api.get(
            `/Quote?filters[0].Field=DealId&filters[0].Condition=NUMBER.IN&filters[0].Value=${batch.join(
              ','
            )}`
          );
          results.push(...response.data);
        } catch (error) {
          Utils.logError(error);
          setLoading(false);
          message.error('Oops. Algo deu errado ao buscar os Negócios!');
          throw error;
        }
      }
    }

    return results;
  };

  const fetchSlots = async () => {
    const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));
    const slotsToFunction = await api
      .get(
        `/Slot?filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${scheduleData?.trainingCenterId}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao tentar buscar os Slots!');
      });

    const mappedSlots = slotsToFunction.map((slot) => ({ key: slot.id, ...slot }));
    setSlots(mappedSlots);
    return slotsToFunction;
  };

  const callSlotGeneration = (ct, readyDate, startDate, endDate, slotsToFunction) => {
    const dateKey = moment(readyDate ?? moment(scheduleDate)).format('YYYY-MM-DD');
    let filterStartDate = moment(new Date(moment(scheduleDate)).setHours(0, 0, 0, 1)).format(
      'YYYY-MM-DDTHH:mm:ss'
    );
    let filterEndDate = null;

    if (startDate && endDate) {
      filterStartDate = moment(startDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
      filterEndDate = moment(endDate.setHours(23, 59, 59, 99)).format('YYYY-MM-DDTHH:mm:ss');
    }

    // Inicio do calculo de horas de cada Slot das Pistas
    const resources = {};
    const resourcesSlots = {};
    SchedulingFunctions.calculateResourceSlots(
      resources,
      ct ?? trainingCenter,
      slotsToFunction,
      filterStartDate,
      filterEndDate
    );
    SchedulingFunctions.generateResourceSlots(resources, resourcesSlots);
    setCtResourcesSlots(resourcesSlots);
    setCtResources(resources[dateKey]);
  };

  const fetchTasks = async (ct, startDate, endDate) => {
    try {
      const storageDate = JSON.parse(localStorage.getItem('conecta__scheduleData'))?.date;

      let filterStartDate = null;
      let filterEndDate = null;

      if (!startDate || !endDate) {
        filterStartDate = moment(new Date(storageDate).setHours(0, 0, 0, 1)).format(
          'YYYY-MM-DDTHH:mm:ss'
        );
        filterEndDate = moment(new Date(storageDate).setHours(23, 59, 59, 999)).format(
          'YYYY-MM-DDTHH:mm:ss'
        );
      } else {
        filterStartDate = moment(startDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
        filterEndDate = moment(endDate.setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');
      }

      let filter = `filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${
        ct?.id ?? trainingCenter?.id
      }`;
      filter += `&filters[1].Field=StartDate&filters[1].Condition=DATE.>=&filters[1].Value=${filterStartDate}`;
      filter += `&filters[2].Field=StartDate&filters[2].Condition=DATE.<=&filters[2].Value=${filterEndDate}`;

      const tasks = await api.get(`/Task?${filter}`).then((res) => res.data);

      if (tasks?.length > 0) {
        const dealIds = [...new Set(tasks?.map(({ dealId }) => dealId))];
        const quotesRes = await fetchQuotes(dealIds);

        const mappedTasks = tasks.map((task, index) => {
          const quote = quotesRes?.find(({ dealId }) => dealId === task.dealId);

          // Calcula a Duração do Treinamento
          let durationHours = 0;
          if (task.startHour && task.endHour) {
            const durationMinutes = moment(new Date(task.endHour)).diff(
              new Date(task.startHour),
              'minutes'
            );
            durationHours = durationMinutes / 60;
          }
          const productName = getProductName(task.product);
          return {
            ...quote,
            ...task,
            text: productName,
            index,
            durationHours,
            oldMainInstructorId: task?.mainInstructor?.userId,
            oldAssistantInstructorId: task?.assistantInstructor?.userId,
          };
        });

        SchedulingFunctions.getOverviewData(
          mappedTasks,
          setOverview,
          filterStartDate,
          filterEndDate
        );

        SchedulingFunctions.getResumeData(mappedTasks, setResume, filterStartDate, filterEndDate);

        if (taskRescheduling) {
          const [tasksResc] = taskRescheduling;
          const [taskResc] = tasksResc.tasks;

          setAppointments(mappedTasks.filter((x) => x.id !== taskResc.id));
        } else {
          setAppointments(mappedTasks);
        }
      } else {
        setAppointments([]);
        setOverview([]);
        setResume([]);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar as tarefas!');
    }
  };

  const fetchTrainingCenter = async () => {
    try {
      setLoading(true);
      const ctId = parseInt(
        JSON.parse(localStorage.getItem('conecta__scheduleData'))?.trainingCenterId,
        10
      );

      const response = await api
        .get(
          `/TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento`
        )
        .then(async (res) => res.data);

      const filteredAndSortedTrainingCenters = response
        .filter(
          (tc) =>
            tc.ctQtySlots > 0 ||
            tc.inCompanyQtySlots > 0 ||
            tc.eadQtySlots > 0 ||
            tc.serviceQtySlots > 0
        )
        .sort((a, b) => b.ctQtyslots - a.ctQtyslots);

      const trainingCenter = filteredAndSortedTrainingCenters?.find(({ id }) => id === ctId);
      const slotsToFunction = await fetchSlots(trainingCenter);
      callSlotGeneration(trainingCenter, null, null, null, slotsToFunction);
      setTrainingCenterOptions(filteredAndSortedTrainingCenters);
      await fetchTasks(trainingCenter);

      setTrainingCenter(trainingCenter);
      setLoading(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscas os dados do CT!');
    } finally {
      setLoading(false);
    }
  };

  const fetchInstructors = async () => {
    try {
      const ctId = JSON.parse(localStorage.getItem('conecta__scheduleData'))?.trainingCenterId;

      const filter = `/Instructor?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Instrutor&filters[1].Value=${ctId}&filters[1].Condition=NUMBER.ARRAY_CONTAIN&filters[1].Field=WorkTrainingCenters`;
      const companyInstructors = await api.get(filter).then((res) => res.data);

      const chunkSize = 30;
      let instructors = [];

      for (let i = 0; i < companyInstructors?.length; i += chunkSize) {
        const chunkInstructors = companyInstructors?.slice(i, i + chunkSize);
        const instructorIds = chunkInstructors.map(({ id }) => id).toString();
        const userFilter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${instructorIds}`;

        const chunkInstructorOptions = await api.get(userFilter).then((res) => res.data);
        const mappedInstructors = chunkInstructorOptions.map((item) => {
          const instructorData = companyInstructors.find(({ id }) => id === item.company.id);

          return { ...instructorData, ...item };
        });
        instructors = [...instructors, ...mappedInstructors];
      }

      setInstructorOptions(instructors);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os Instrutores!');
    }
  };

  const fetchDrivers = async () => {
    try {
      const filter = `/Driver?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Motorista`;
      const companyDrivers = await api.get(filter).then((res) => res.data);

      const chunkSize = 30;
      let drivers = [];

      for (let i = 0; i < companyDrivers?.length; i += chunkSize) {
        const chunkDrivers = companyDrivers?.slice(i, i + chunkSize);
        const driverIds = chunkDrivers.map(({ id }) => id).toString();
        const userFilter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${driverIds}`;

        const chunkDriverOptions = await api.get(userFilter).then((res) => res.data);
        drivers = [...drivers, ...chunkDriverOptions];
      }

      setDriverOptions(drivers);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os Motoristas!');
    }
  };

  const fetchTransportCompanies = async () => {
    try {
      const response = await api.get(
        '/Transport?filters[0].Field=CompanyType&filters[0].Condition=IN&filters[0].Value=Transporte'
      );

      const responseMapped = response.data.map((tc) => ({
        ...tc,
        value: tc.id,
        label: tc.commercialName,
      }));
      setTransportOptions(responseMapped);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar as Empresas de Transporte!');
    }
  };

  const fetchVehicles = () =>
    api
      .get('/Vehicle')
      .then((res) => {
        const mappedOptions = res.data.map((item) => ({
          ...item,
          label: `${item.licensePlate} - ${item.type.name} (${item.capacity} pessoas)`,
        }));

        setVehicleOptions(mappedOptions);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar Veículos!');
      });

  const fetchTrainings = () =>
    api
      .get('/Trainings')
      .then((res) => setTrainingsOptions(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Grupos de Treinamento!');
      });

  const fetchBlockedDays = () =>
    api
      .get(
        `/BlockedDay?filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${
          JSON.parse(localStorage.getItem('conecta__scheduleData'))?.trainingCenterId
        }`
      )
      .then((res) => setBlockedDates(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Dias Bloqueados do CT!');
      });

  const fetchInstructorsBlockedDays = () =>
    api
      .get(
        `/BlockedDay?filters[0].Field=InstructorId&filters[0].Condition=ISNOTNULL&filters[0].Value=null`
      )
      .then((res) => setInstructorsBlockedDates(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Dias Bloqueados dos Instrutores!');
      });

  const fetchStages = () =>
    api
      .get(`/Stage`)
      .then((res) => setStages(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const fetchReasons = async () => {
    const reasons = await api
      .get(
        `/DenyTaskReason?filters[0].Field=Type&filters[0].Condition=IN&filters[0].Value=Scheduling,Logistics,NoBilling`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    setReasonsOptions(
      reasons?.map(({ id, name, type }) => ({
        label: name,
        value: id,
        type,
      }))
    );

    setReasonNoBillingOptions(
      reasons
        ?.filter(({ type }) => type === 'NoBilling')
        .map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          type: item.type,
        }))
    );
  };

  const fetchTaskAcceptanceDeadline = () =>
    api
      .get(`/Settings?id=1`)
      .then(async (res) => {
        const decryptedResponse = Utils.decryptSettings(res.data);

        taskAcceptanceDeadline = decryptedResponse.taskAcceptanceDeadline;
        SchedulingFunctions.fetchBitrixProducts(
          decryptedResponse?.bitrixWebhookUrl,
          setBitrixProducts
        );
        UserFunctions.fetchBitrixUsers(decryptedResponse?.bitrixWebhookUrl, setBitrixUsers);
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const getCurrentDate = () => scheduleDate;

  const fetchOpenTraining = async () => {
    if (urlParams?.action === 'OpenTraining') {
      const tasks = await api
        .get(
          `/Task?filters[0].Field=Id&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${parseInt(
            urlParams.taskId,
            10
          )}`
        )
        .then((res) => res.data);

      if (tasks?.length > 0) {
        const [task] = tasks;
        const [quote] = await fetchQuotes([task.dealId]);

        const taskMapped = { ...quote, ...task };
        setOpenTraining(taskMapped);
      }
    }
  };

  const onContentReady = async (e) => {
    const { component } = e;
    const dorpdownItens = [];
    const date = moment(component.option('currentDate')).format('YYYY-MM-DD');

    const currentDateChanged = date !== scheduleDate;
    scheduleDate = date;

    const toolbar = [
      {
        location: 'before',
        defaultElement: 'dateNavigator',
      },
      {
        location: 'center',
        template: `<h2 class="schedule-title">${trainingCenter?.commercialName ?? ''}</h2>`,
      },
    ];

    if (firstOpen === true && urlParams?.action === 'OpenNewSlots') {
      newSlotPopupRef.current.instance.show();
    }

    if (
      fieldsPermissions?.NewSlot?.access >= 1 &&
      trainingCenter?.workDays?.includes(component.option('currentDate')?.getDay()) &&
      component.option('currentDate') >= new Date().setHours(0, 0, 0, 0)
    ) {
      dorpdownItens.push({
        name: 'Novo Slot',
        showPopup: () => newSlotPopupRef.current.instance.show(),
        index: 3,
      });
    }

    if (fieldsPermissions?.BookInstructorsButton?.access >= 1) {
      dorpdownItens.push({
        name: 'Reserva Instrutores',
        showPopup: () => instructorBookingPopupRef.current.instance.show(),
        index: 0,
      });
    }

    dorpdownItens.push(
      {
        name: 'Resumo',
        showPopup: () => resumePopupRef.current.instance.show(),
        index: 1,
      },
      {
        name: 'Overview',
        showPopup: () => overviewPopupRef.current.instance.show(),
        index: 2,
      }
    );

    toolbar.push({
      location: 'after',
      widget: 'dxDropDownButton',
      options: {
        text: 'Mais',
        displayExpr: 'name',
        width: 150,
        stylingMode: 'contained',
        onItemClick: (e) => {
          e.itemData.showPopup();
        },
        items: dorpdownItens.sort((a, b) => a.index - b.index),
      },
    });

    const scheduledStudents = component._filteredItems
      ?.filter(({ resource }) => resource === 'CT')
      ?.map(({ qtyStudentsPerClass }) => qtyStudentsPerClass)
      .reduce((startVal, nextVal) => startVal + nextVal, 0);
    setUsedCapacity(scheduledStudents);

    if (currentDateChanged) {
      if (!firstOpen) {
        fetchTasks(trainingCenter, component.getStartViewDate(), component.getEndViewDate());
      }
      callSlotGeneration(
        trainingCenter,
        date,
        component.getStartViewDate(),
        component.getEndViewDate(),
        slots
      );
      component.option('toolbar', toolbar);
    }

    if (firstOpen && bitrixUsers.length > 0) {
      firstOpen = false;
      component.option('toolbar', toolbar);
    }

    if (urlParams?.action === 'OpenTraining') {
      // trainingOpened = true;
      component.showAppointmentPopup(openTraining);
    }

    if (currentCTName !== trainingCenter?.name) {
      currentCTName = trainingCenter?.name;
      component.option('toolbar', toolbar);
    }
  };

  const exitReschedulingMode = () => {
    const [firstTask] = taskRescheduling[0].tasks;

    appointments.push(firstTask);
    setAppointments([...appointments]);
    setTaskRescheduling(null);
  };

  useEffect(() => {
    if (urlParams?.action === 'OpenTraining') {
      // trainingOpened = true;
      schedulerRef?.current?.instance?.showAppointmentPopup(openTraining);
    }
  }, [schedulerRef]);

  useLayoutEffect(() => {
    if (urlParams?.action === 'OpenNewSlots') {
      const scheduleStorageData = {
        trainingCenterId: urlParams.ctId,
        trainingCenter: urlParams.ctName,
        date: new Date(),
        viewType: 'day',
        maxCapacityPerDay: urlParams.ctMaxCapacity,
      };

      localStorage.setItem('conecta__scheduleData', JSON.stringify(scheduleStorageData));
    }

    setScheduleParams(JSON.parse(localStorage.getItem('conecta__scheduleData')));

    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'ScheduleScheduling') {
          fields.forEach(({ name, access, isRequired, isADM }) => {
            permissions[name] = { access, isRequired, isADM };
          });
        }
      }
    );

    setFieldsPermissions(permissions);
    fetchTaskAcceptanceDeadline();
    fetchTrainingCenter();
    fetchBlockedDays();
    fetchInstructorsBlockedDays();
  }, []);

  useEffect(() => {
    fetchInstructors();
    fetchDrivers();
    fetchTransportCompanies();
    fetchVehicles();
    fetchTrainings();
    fetchStages();
    fetchReasons();
    fetchOpenTraining();
  }, []);

  // Reseta o carregamento da tela quando o usuário sai da tela
  useEffect(
    () => () => {
      firstOpen = true;
      scheduleDate = moment(new Date()).format('YYYY-MM-DD');
    },
    [location]
  );

  if (loading || !ctResources || !ctResourcesSlots) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!loading && ctResources && ctResourcesSlots) {
    return (
      <Row gutter={[12]}>
        <Col span={5}>
          {stages && trainingsOptions && trainingCenter && (
            <QuoteList
              loading={loadingSchedule}
              stages={stages}
              trainings={trainingsOptions}
              schedulerRef={schedulerRef}
              dealId={dealId}
              bitrixUsers={bitrixUsers}
              fetchQuotes={fetchQuotes}
              quoteList={quoteList}
              setQuoteList={setQuoteList}
              trainingCenterOptions={trainingCenterOptions}
              fetchInstructors={fetchInstructors}
              fetchSlots={fetchSlots}
              callSlotGeneration={callSlotGeneration}
              fetchTasks={fetchTasks}
              setTrainingCenter={setTrainingCenter}
              taskRescheduling={taskRescheduling}
              exitReschedulingMode={exitReschedulingMode}
            />
          )}
        </Col>
        {scheduleParams && (
          <Col span={19}>
            <NewSlotPopup
              newSlotPopupRef={newSlotPopupRef}
              slots={slots}
              setSlots={setSlots}
              getCurrentDate={getCurrentDate}
              trainingCenter={trainingCenter}
              bitrixProducts={bitrixProducts}
              callSlotGeneration={callSlotGeneration}
            />
            <OverviewPopup
              overviewPopupRef={overviewPopupRef}
              overviewList={overview}
              totalStudents={trainingCenter?.maxCapacityPerDay}
              getCurrentDate={getCurrentDate}
            />
            <ResumePopup
              resumePopupRef={resumePopupRef}
              resumeList={resume}
              schedulerRef={schedulerRef}
              getCurrentDate={getCurrentDate}
              ctName={trainingCenter?.name}
            />
            <InstructorBookingPopup
              instructorBookingPopupRef={instructorBookingPopupRef}
              getCurrentDate={getCurrentDate}
              instructors={instructorOptions}
              instructorsBlockedDates={instructorsBlockedDates}
            />
            <Scheduler
              ref={schedulerRef}
              disabled={loadingSchedule}
              id="scheduleScheduling"
              views={views}
              groups={groups}
              groupByDate
              dataSource={appointments}
              defaultCurrentView="Dia"
              defaultCurrentDate={new Date(scheduleParams.date)}
              startDayHour={8}
              endDayHour={18}
              showAllDayPanel={false}
              firstDayOfWeek={1}
              maxAppointmentsPerCell={1}
              dataCellRender={renderDataCell}
              dateCellRender={renderDateCell}
              resourceCellRender={resourceCellRender}
              onAppointmentFormOpening={onAppointmentFormOpening}
              onAppointmentUpdating={onAppointmentUpdating}
              onAppointmentDeleting={onAppointmentDeleting}
              appointmentComponent={appointmentRender}
              appointmentTooltipRender={renderAppointmentTooltip}
              onContentReady={onContentReady}
              crossScrollingEnabled
              showCurrentTimeIndicator={false}
              editing={{
                allowResizing: false,
              }}
              onCellClick={(e) => {
                e.cancel = true;
              }}
            >
              <AppointmentDragging
                group={draggingGroupName}
                onAdd={onAppointmentAdd}
                onDragStart={(e) => {
                  if (!(fieldsPermissions?.CanMoveScheduledCards.access > 0)) {
                    e.cancel = true;
                  }
                }}
              />
              <Resource
                fieldExpr="resourceId"
                allowMultiple={false}
                dataSource={ctResources}
                label="Recurso"
              />
            </Scheduler>
            <ReasonModal
              isModalOpen={isReasonModalOpen}
              setModalOpen={setReasonModalOpen}
              reasonsOptions={reasonsOptions}
              currentOpenTask={currentOpenTask}
              event={event}
              eventSource="Scheduling"
              onOk={onModalOk}
            />
            <ModalUploadFile
              isOpen={isUploadFileModalOpen}
              setIsOpen={setUploadFileModalOpen}
              loading={loading}
              currentOpenTask={currentOpenTask}
              updateTask={onAppointmentUpdating}
              type={uploadFileType}
            />
            <OccurrencesPopup
              isVisible={occurrencesPopupVisible}
              onClose={() => setOccurrencesPopupVisible(false)}
              currentOpenTask={currentOpenTask}
            />

            {emailConfirmPopupVisible && (
              <EmailConfirmPopup
                onClose={() => setEmailConfirmPopupVisible(false)}
                currentOpenTask={currentOpenTask}
                type={confirmEmailType}
              />
            )}
          </Col>
        )}
      </Row>
    );
  }
}

const ScheduleScheduling = memo(ScheduleSchedulingComponent);
export default ScheduleScheduling;
