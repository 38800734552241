/* eslint-disable prettier/prettier */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unsafe-optional-chaining */
import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Col, message, Row, Spin, Switch } from 'antd';
import { List, Scheduler } from 'devextreme-react';
import { AppointmentDragging, Resource } from 'devextreme-react/scheduler';
import moment from 'moment';
import { renderToStaticMarkup } from 'react-dom/server';
import { useLocation, useParams } from 'react-router-dom/dist';

import { CalendarOutlined, LoadingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { isArray } from '@craco/craco/lib/utils';

import Utils from '../../Assets/Scripts/Utils';
import CustomDropdown from '../../Components/CustomDropdown/CustomDropdown';
import ReasonModal from '../../Components/ReasonModal/ReasonModal';
import Appointment from '../../Components/Schedule/Appointment';
import DataCell from '../../Components/Schedule/DataCell';
import DateCell from '../../Components/Schedule/DateCell';
import ListItem from '../../Components/Schedule/ListItem';
import ModalUploadFile from '../../Components/Schedule/ModalUploadFile';
import OverviewPopup from '../../Components/Schedule/OverviewPopup';
import ResourceCellRender from '../../Components/Schedule/ResourceCellRender';
import ResumePopup from '../../Components/Schedule/ResumePopup';
import ScheduleUtils from '../../Components/Schedule/ScheduleUtils';
import TaskCommentFunctions from '../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../Services/axiosService';
import { fetchTaskById } from '../ScheduleConecta/API/ScheduleSchedulingAPI';
import SchedulingFunctions from '../ScheduleConecta/SchedulingFunctions';

import '../../Components/Schedule/ListItem.scss';
import '../../Components/Schedule/TasksList.scss';

let scheduleView = 'Dia';
let scheduleDate = moment(new Date()).format('YYYY-MM-DD');
let taskAcceptanceDeadline = 0;

let currentOpenTask = null;
let event = '';
let onModalOk = null;
let firstOpen = true;
let trainingOpened = false;

const oldTaskStage = null;
let timeout = null;
let currentListItem = null;
let filterCount = 0;
let filterCountNew = 0;

const views = [
  {
    name: 'Dia',
    type: 'day',
    cellDuration: 60,
  },
];

const notifySameInstructors = () => {
  message.warning('O "Instrutor" e o "Instrutor Auxiliar" não podem ser o mesmo!');
};

function ScheduleTrainingCenterComponent() {
  const draggingGroupName = 'appointmentsGroup';
  const groups = ['resourceId'];
  const schedulerRef = useRef(null);
  const overviewPopupRef = useRef(null);
  const resumePopupRef = useRef(null);
  const location = useLocation();
  const urlParams = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [currentView, setCurrentView] = useState('Dia');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [trainingCenter, setTrainingCenter] = useState();
  const [blockedDates, setBlockedDates] = useState([]);
  const [slots, setSlots] = useState([]);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [instructorOptions, setInstructorOptions] = useState();
  const [driverOptions, setDriverOptions] = useState();
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [stages, setStages] = useState();
  const [changeVisibility, setChangeVisibility] = useState(false);
  const [isReasonModalOpen, setReasonModalOpen] = useState(false);
  const [reasonsOptions, setReasonsOptions] = useState();
  const [stagesValue, setStagesValue] = useState([3, 4, 5, 9, 10, 12, 13, 14]);
  const [stagesOptions, setStagesOptions] = useState();
  const [ctResources, setCtResources] = useState();
  const [ctResourcesSlots, setCtResourcesSlots] = useState();
  const [overview, setOverview] = useState([]);
  const [resume, setResume] = useState([]);
  const [isUploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [uploadFileType, setUploadFileType] = useState('');
  const [reasonNoBillingOptions, setReasonNoBillingOptions] = useState([]);
  const [trainingCenterOptions, setTrainingCenterOptions] = useState([]);
  const [selectedTrainingCenterId, setSelectedTrainingCenterId] = useState();
  const [lastSelectedTrainingCenterId, setLastSelectedTrainingCenterId] = useState();

  const denyTask = (task) => {
    const data = JSON.parse(JSON.stringify(task)); // Deep Copy da task
    const denyStage = stages?.find(({ id }) => id === 4);
    data.stage = denyStage;
    data.acceptanceDeadline = null;

    onModalOk = async () => {
      setLoadingSchedule(true);

      await api
        .put('/Task/UpdateTaskStage', data)
        .then(() => {
          message.success('Treinamento Negado!');
        })
        .catch((error) => {
          Utils.logError(error);
          message.error('Oops. Algo deu errado ao negar o treinamento!');
        });

      task.stage = denyStage; // Altera o valor no objeto original
      setLoadingSchedule(false);
    };

    event = 'TaskDenied';
    currentOpenTask = data;
    setReasonModalOpen(true);
  };

  const onAppointmentUpdating = async (e, openReasonModal = true) => {
    try {
      const newTaskData = e.newData;
      e.cancel = true;
      const oldTaskData = JSON.parse(JSON.stringify(e.oldData));

      newTaskData.endDate = moment(new Date(newTaskData.endDate)).format('YYYY-MM-DDTHH:mm:ss');
      newTaskData.startDate = moment(new Date(newTaskData.startDate)).format('YYYY-MM-DDTHH:mm:ss');

      if (oldTaskStage !== newTaskData.stage.id && newTaskData.stage.id === 4 && openReasonModal) {
        onModalOk = () => onAppointmentUpdating(e, false);
        event = 'TaskDenied';
        currentOpenTask = newTaskData;
        setReasonModalOpen(true);
        return;
      }

      if (
        newTaskData.mainInstructor?.userId &&
        newTaskData.assistantInstructor?.userId &&
        newTaskData.mainInstructor?.userId === newTaskData.assistantInstructor?.userId
      ) {
        notifySameInstructors();
        return;
      }

      const waitingStage = stages?.find(({ id }) => id === 1);

      if (newTaskData.mainInstructor?.userId) {
        const mainInstructor = instructorOptions?.find(
          ({ id }) => id === newTaskData.mainInstructor.userId
        );

        newTaskData.mainInstructor = {
          userId: mainInstructor.id,
          userName: mainInstructor.name,
          stage: waitingStage,
          acceptanceDeadline: new Date(
            moment(newTaskData.startDate).add(taskAcceptanceDeadline, 'hours')
          ),
        };
      } else {
        newTaskData.mainInstructor = null; // Garante que ta nulo
      }

      if (newTaskData.assistantInstructor?.userId) {
        const assistantInstructor = instructorOptions?.find(
          ({ id }) => id === newTaskData.assistantInstructor.userId
        );

        newTaskData.assistantInstructor = {
          userId: assistantInstructor.id,
          userName: assistantInstructor.name,
          stage: waitingStage,
          acceptanceDeadline: new Date(
            moment(newTaskData.startDate).add(taskAcceptanceDeadline, 'hours')
          ),
        };
      } else {
        newTaskData.assistantInstructor = null; // Garante que ta nulo
      }

      if (newTaskData.driver?.userId) {
        const driver = driverOptions?.find(({ id }) => id === newTaskData.driver.userId);

        newTaskData.driver = {
          userId: driver.id,
          userName: driver.name,
          stage: waitingStage,
          acceptanceDeadline: new Date(
            moment(newTaskData.startDate).add(taskAcceptanceDeadline, 'hours')
          ),
        };
      } else {
        newTaskData.driver = null; // Garante que ta nulo
      }

      let newStageId = newTaskData.stage.id;
      if (newTaskData.hasDocumentation) {
        newStageId = 5;
      }

      const newStage = stages?.find(({ id }) => id === newStageId);
      newTaskData.stage = newStage;
      newTaskData.endDate = new Date(newTaskData.endDate);
      newTaskData.startDate = new Date(newTaskData.startDate);

      if (oldTaskStage !== newTaskData.stage.id && newTaskData.stage.id === 3) {
        newTaskData.acceptanceDeadline = null;
        await TaskCommentFunctions.addTaskComment(newTaskData, 'TaskAccepted', 'CT', '');
      }

      setLoadingSchedule(true);
      await api.put('/Task/UpdateTaskScheduling', newTaskData).then(() => {
        message.success('Treinamento atualizado!');
      });

      await TaskCommentFunctions.addTaskCommentActivities(newTaskData, oldTaskData, 'CT');
      await TaskCommentFunctions.addTaskCommentHistory(newTaskData, oldTaskData, 'CT');

      // Enviar Notificação para os Operadores
      const stagesToNotify = [3, 10, 12, 13];
      if (stagesToNotify.includes(newTaskData.stage.id)) {
        // Notifica Instrutor
        if (newTaskData?.mainInstructor?.userId) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskReceived',
            'mainInstructor',
            newTaskData.mainInstructor.userId
          );
        }
        if (
          (oldTaskData?.mainInstructor?.userId && !newTaskData?.mainInstructor?.userId) ||
          (oldTaskData?.mainInstructor?.userId &&
            oldTaskData?.mainInstructor?.userId !== newTaskData?.mainInstructor?.userId)
        ) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskInstructorRemoved',
            'mainInstructor',
            oldTaskData.mainInstructor.userId
          );
        }

        // Notifica Instrutor Auxiliar
        if (newTaskData?.assistantInstructor?.userId) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskReceived',
            'assistantInstructor',
            newTaskData.assistantInstructor.userId
          );
        }
        if (
          (oldTaskData?.assistantInstructor?.userId && !newTaskData?.assistantInstructor?.userId) ||
          (oldTaskData?.assistantInstructor?.userId &&
            oldTaskData?.assistantInstructor?.userId !== newTaskData?.assistantInstructor?.userId)
        ) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskInstructorRemoved',
            'assistantInstructor',
            oldTaskData.assistantInstructor.userId
          );
        }

        // Notifica Motorista
        if (newTaskData?.driver?.userId) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskReceived',
            'driver',
            newTaskData.driver.userId
          );
        }
        if (
          (oldTaskData?.driver?.userId && !newTaskData?.driver?.userId) ||
          (oldTaskData?.driver?.userId &&
            oldTaskData?.driver?.userId !== newTaskData?.driver?.userId)
        ) {
          await ScheduleUtils.sendPushNotification(
            newTaskData,
            'TaskInstructorRemoved',
            'driver',
            oldTaskData.driver.userId
          );
        }
      }

      // Atualiza a task em tela sem precisar fazer o get denovo
      const appointment = appointments.find(({ id }) => id === newTaskData.id);
      const indexAppointment = appointments.indexOf(appointment);
      appointments.splice(indexAppointment, 1, { ...appointment, ...newTaskData });
      setAppointments([...appointments]);
      setLoadingSchedule(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao atualizar o treinamento!');
    }
  };

  const onAppointmentFormOpening = async (e) => {
    const { form, popup } = e;

    // Loader para limpar o form original e exibir carregamento enquanto busca as Atividades
    const loader = [
      {
        template: () =>
          renderToStaticMarkup(
            <Spin
              style={{ width: 'calc(200% + 30px)' }}
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 48,
                  }}
                  spin
                />
              }
            />
          ),
        label: {
          text: ' ',
        },
      },
    ];

    form.option('items', [
      {
        colCountByScreen: {
          lg: 1,
        },
        colSpan: 1,
        itemType: 'group',
        name: 'mainGroup',
        items: loader,
      },
    ]);

    const [newTask] = await fetchTaskById(e.appointmentData.id);
    const openTask = JSON.parse(JSON.stringify(newTask)); // Deep Copy da task
    currentOpenTask = openTask;

    if (isArray(newTask?.quoteProducts)) {
      let concatenatedProducts = '';
      newTask?.quoteProducts?.forEach((product) => {
        concatenatedProducts += `${product.name}\n`;
      });
      newTask.quoteProducts = concatenatedProducts;
    }

    // Titulo Popup
    popup.option('showTitle', true);
    popup.option('title', newTask.cardTitle);
    popup.option('hideOnOutsideClick', false);
    popup.option('showCloseButton', true);

    // Botões Popup
    const toolbar = popup.option('toolbarItems');
    toolbar[0].options.text = 'Salvar';
    toolbar[0].options.type = 'success';
    toolbar[0].location = 'center';
    toolbar[1].options = {};
    toolbar[1].location = 'center';
    toolbar[1].options.text = 'Cancelar';

    toolbar.push({
      location: 'before',
      widget: 'dxButton',
      options: {
        text: 'Modelo Lista de Presença',
        onClick: () => {
          window.open(
            'https://firebasestorage.googleapis.com/v0/b/portal-conecta-oficial.appspot.com/o/modelo-lista-presenca.xlsx?alt=media&token=f72f1017-54b9-42b5-b4f4-d4c6839cb23f',
            '_blank'
          );
        },
      },
      shortcut: 'cancel',
    });

    // 10 = Pré-Reservado / 12 = Reservado / 3 = Confirmado / 13 = Reservado Sem Confirmação
    if (
      openTask.stage?.id === 10 ||
      openTask.stage?.id === 12 ||
      openTask.stage?.id === 3 ||
      openTask.stage?.id === 13
    ) {
      toolbar.push({
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Negar',
          type: 'danger',
          onClick: () => {
            denyTask(newTask);
          },
        },
        shortcut: 'cancel',
      });
    }

    popup.option('toolbarItems', toolbar);

    const commentsTask = await api
      .get(
        `/TaskComment?filters[0].Field=TaskId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${newTask.id}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as Atividades!');
      });

    const commentsQuote = await api
      .get(
        `/TaskComment?filters[0].Field=DealId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${newTask.dealId}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar as Atividades!');
      });

    const comments = [...commentsTask, ...commentsQuote]?.sort((a, b) => a.id - b.id);

    const availableInstructors = await SchedulingFunctions.fetchInstructorsAvailability(
      instructorOptions,
      openTask.startDate,
      openTask.endDate
    );

    let combinedInstructorList = [];
    if (availableInstructors?.length > 0) {
      combinedInstructorList = instructorOptions?.map((instructor) => {
        const availability = availableInstructors.find((avail) => avail.id === instructor.id);

        return {
          label: instructor.name,
          value: instructor.id,
          hasTrainings: availability?.hasTrainings ?? false,
          isBlocked: availability?.isBlocked ?? false,
          disabled: availability?.isBlocked ?? false,
        };
      });
    }

    // Gera os campos do Form
    const generalItems = ScheduleUtils.generateGeneralFormFields(
      e,
      fieldsPermissions,
      stages,
      trainingsOptions,
      ctResources,
      setUploadFileModalOpen,
      setUploadFileType,
      combinedInstructorList,
      onAppointmentUpdating
    );

    const formGroups = [];
    formGroups.push({
      colCountByScreen: {
        lg: 1,
      },
      colSpan: 1,
      itemType: 'group',
      name: 'mainGroup',
      caption: 'Geral',
      items: generalItems,
    });

    formGroups.push({
      colCountByScreen: {
        lg: 1,
      },
      colSpan: 1,
      itemType: 'group',
      name: 'taskComments',
      caption: 'Atividade',
      items: TaskCommentFunctions.generateTaskCommentsFields(comments, fieldsPermissions),
    });

    if (ScheduleUtils.checkLogisticFormFieldsAccess(fieldsPermissions)) {
      formGroups.push({
        colCountByScreen: {
          lg: 1,
        },
        colSpan: 1,
        itemType: 'group',
        name: 'logisticsInformation',
        caption: 'Logística',
        items: ScheduleUtils.generateLogisticsFormFields(
          e,
          form,
          fieldsPermissions,
          onAppointmentUpdating,
          setUploadFileType,
          setUploadFileModalOpen
        ),
      });
    }

    if (ScheduleUtils.checkFinanceFormFieldsAccess(fieldsPermissions)) {
      formGroups.push({
        colCountByScreen: {
          lg: 1,
        },
        colSpan: 1,
        itemType: 'group',
        name: 'financeInformation',
        caption: 'Financeiro',
        items: ScheduleUtils.generateFinanceFormFields(
          e,
          fieldsPermissions,
          onAppointmentUpdating,
          setUploadFileType,
          setUploadFileModalOpen,
          reasonNoBillingOptions,
          trainingCenterOptions
        ),
      });
    }

    form.option('items', formGroups);
  };

  const onListItemClick = (e) => {
    const data = e.itemData;
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
      }, 300);
    } else if (data.id === currentListItem) {
      schedulerRef.current.instance.showAppointmentPopup(data);
    }
    currentListItem = data.id;
  };

  const filterAppointments = (filter) => {
    const stagesOptions = filter?.component?.option('selectedItems')?.map(({ value }) => value);
    filterCountNew += 1;
    setStagesValue(stagesOptions);
  };

  const onCurrentViewChange = (value) => {
    localStorage.setItem('conecta__currentView', value);
    setCurrentView(value);
  };

  const onCurrentDateChange = (value) => {
    localStorage.setItem('conecta__currentDate', value);
    setCurrentDate(value);
  };

  const renderDataCell = (itemData) => (
    <DataCell
      itemData={itemData}
      workDays={trainingCenter?.workDays}
      blockedDates={blockedDates}
      ctResourcesSlots={ctResourcesSlots}
      appointments={appointments.filter(({ stage: { id } }) => stagesValue?.includes(id))}
      showResourceCapacity
    />
  );

  const renderDateCell = (itemData) => (
    <DateCell itemData={itemData} workDays={trainingCenter?.workDays} blockedDates={blockedDates} />
  );

  const resourceCellRender = (itemData) => (
    <ResourceCellRender itemData={itemData} trainings={trainingsOptions} />
  );

  const appointmentRender = (itemData) => <Appointment itemData={itemData} type="trainingCenter" />;

  const renderItem = (itemData) => (
    <ListItem itemData={itemData} denyTask={denyTask} loading={loadingSchedule} />
  );

  const fetchQuotes = async (dealIds) => {
    const quoteFilter = `?filters[0].Field=DealId&filters[0].Condition=NUMBER.IN&filters[0].Value=${dealIds}`;

    return api
      .get(`/Quote${dealIds ? quoteFilter : ''}`)
      .then((res) => res.data)
      .catch((error) => {
        setLoading(false);
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os Negócios!');
      });
  };

  const fetchSlots = async () => {
    const ctId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.companyId, 10);
    const filterStartDate = moment(new Date().setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');

    const slotsToFunction = await api
      .get(
        `/Slot?filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${ctId}&filters[1].Field=Date&filters[1].Condition=DATE.>=&filters[1].Value=${filterStartDate}`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });
    const mappedSlots = slotsToFunction.map((slot) => ({ key: slot.id, ...slot }));
    setSlots(mappedSlots);
    return slotsToFunction;
  };

  const callSlotGeneration = (ct, readyDate, startDate, endDate, slotsToFunction) => {
    const storageCurrentDate = localStorage.getItem('conecta__currentDate');
    const dateKeyDate = readyDate ?? storageCurrentDate ? new Date(storageCurrentDate) : new Date();
    const dateKey = moment(dateKeyDate).format('YYYY-MM-DD');
    let filterStartDate = moment(dateKeyDate).format('YYYY-MM-DDTHH:mm:ss');
    let filterEndDate = null;

    if (startDate && endDate) {
      filterStartDate = moment(startDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
      filterEndDate = moment(endDate.setHours(23, 59, 59, 99)).format('YYYY-MM-DDTHH:mm:ss');
    }

    // Inicio do calculo de horas de cada Slot das Pistas
    const resources = {};
    const resourcesSlots = {};
    SchedulingFunctions.calculateResourceSlots(
      resources,
      ct ?? trainingCenter,
      slotsToFunction,
      filterStartDate,
      filterEndDate
    );
    SchedulingFunctions.generateResourceSlots(resources, resourcesSlots);
    setCtResourcesSlots(resourcesSlots);
    setCtResources(resources[dateKey]);
  };

  const fetchTasks = async (ct, startDate, endDate) => {
    try {
      let filterStartDate = null;
      let filterEndDate = null;

      if (!startDate || !endDate) {
        filterStartDate = moment(new Date().setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
        filterEndDate = moment(new Date().setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        filterStartDate = moment(startDate.setHours(0, 0, 0, 1)).format('YYYY-MM-DDTHH:mm:ss');
        filterEndDate = moment(endDate.setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss');
      }

      const tasks = await api
        .get(
          `/Task?filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${
            ct?.id ?? trainingCenter?.id
          }&filters[1].Field=StartDate&filters[1].Condition=DATE.>=&filters[1].Value=${filterStartDate}&filters[2].Field=StartDate&filters[2].Condition=DATE.<=&filters[2].Value=${filterEndDate}`
        )
        .then((res) => res.data);

      if (tasks?.length > 0) {
        const dealIds = tasks
          ?.filter(({ stage: { id } }) => stagesValue?.includes(id))
          .map(({ dealId }) => dealId)
          ?.toString();
        const quotesRes = await fetchQuotes(dealIds);

        const mappedTasks = tasks.map((task, index) => {
          const quote = quotesRes?.find(({ dealId }) => dealId === task.dealId);

          // Calcula a Duração do Treinamento
          let durationHours = 0;
          if (task.startHour && task.endHour) {
            const durationMinutes = moment(new Date(task.endHour)).diff(
              new Date(task.startHour),
              'minutes'
            );
            durationHours = durationMinutes / 60;
          }

          return {
            ...quote,
            ...task,
            text: task.product.name,
            index,
            durationHours,
          };
        });

        SchedulingFunctions.getOverviewData(
          mappedTasks,
          setOverview,
          filterStartDate,
          filterEndDate
        );

        SchedulingFunctions.getResumeData(mappedTasks, setResume, filterStartDate, filterEndDate);

        setAppointments(mappedTasks);
      } else {
        setAppointments([]);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar as tarefas!');
    }
  };

  const fetchTrainingCenter = async () => {
    try {
      setLoading(true);
      const ctId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.companyId, 10);
      const userId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.id, 10);

      const trainingCenter = await api
        .get(`/TrainingCenter?id=${ctId}`)
        .then(async (res) => res.data);

      const response = await api
        .get(`/TrainingCenter/UserTrainingCenters?id=${userId}`)
        .then(async (res) => res.data);

      const filteredAndSortedTrainingCenters = response
        .filter(
          (tc) =>
            tc.ctQtySlots > 0 ||
            tc.inCompanyQtySlots > 0 ||
            tc.eadQtySlots > 0 ||
            tc.serviceQtySlots > 0
        )
        .sort((a, b) => b.ctQtyslots - a.ctQtyslots);

      setTrainingCenterOptions(filteredAndSortedTrainingCenters);

      // Definindo o centro de treinamento atual no estado
      if (filteredAndSortedTrainingCenters.length > 0) {
        // Se o centro de treinamento atual corresponder ao carregado pelo ID, defina este, senão, defina o primeiro da lista
        const activeCenter =
          filteredAndSortedTrainingCenters.find((tc) => tc.id === ctId) ||
          filteredAndSortedTrainingCenters[0];
        setSelectedTrainingCenterId(activeCenter.id);
        setTrainingCenter(activeCenter);
      }

      // Fetch additional data based on selected training center
      const slotsToFunction = await fetchSlots(trainingCenter);
      callSlotGeneration(trainingCenter, null, null, null, slotsToFunction);
      await fetchTasks(trainingCenter);

      setLoading(false);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os dados do centro de treinamento!');
    } finally {
      setLoading(false);
    }
  };

  const fetchInstructors = async () => {
    try {
      const ctId = parseInt(JSON.parse(localStorage.getItem('conecta__userData'))?.companyId, 10);

      const filter = `/Instructor?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Instrutor&filters[1].Value=${ctId}&filters[1].Condition=NUMBER.ARRAY_CONTAIN&filters[1].Field=WorkTrainingCenters`;
      const companyInstructors = await api.get(filter).then((res) => res.data);

      const chunkSize = 30;
      let instructors = [];

      for (let i = 0; i < companyInstructors?.length; i += chunkSize) {
        const chunkInstructors = companyInstructors?.slice(i, i + chunkSize);
        const instructorIds = chunkInstructors.map(({ id }) => id).toString();
        const userFilter = `/User?filters[0].Field=Company.Id&filters[0].Condition=NUMBER.IN&filters[0].Value=${instructorIds}`;

        const chunkInstructorOptions = await api.get(userFilter).then((res) => res.data);
        instructors = [...instructors, ...chunkInstructorOptions];
      }

      setInstructorOptions(instructors);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar os Instrutores!');
    }
  };

  const fetchDrivers = () =>
    api
      .get(
        '/User?filters[0].Field=Company.CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Motorista'
      )
      .then((res) => setDriverOptions(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const fetchBlockedDays = () =>
    api
      .get(
        `/BlockedDay?filters[0].Field=TrainingCenterId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${parseInt(
          JSON.parse(localStorage.getItem('conecta__userData'))?.companyId,
          10
        )}`
      )
      .then((res) => setBlockedDates(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const fetchTrainings = () =>
    api
      .get('/Trainings')
      .then((res) => setTrainingsOptions(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const fetchStages = async () => {
    await api
      .get(`/Stage`)
      .then((res) => setStages(res.data))
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const stages = await api
      .get(
        `/Stage?filters[0].Field=Id&filters[0].Condition=NUMBER.IN&filters[0].Value=3,4,5,9,10,12,13,14`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    const mappedStages = stages?.map(({ id: value, name: label }) => ({
      label,
      value,
    }));

    setStagesValue(mappedStages?.map(({ value }) => value));
    setStagesOptions(mappedStages);

    return mappedStages?.map(({ value }) => value);
  };

  const fetchReasons = async () => {
    const reasons = await api
      .get(
        `/DenyTaskReason?filters[0].Field=Type&filters[0].Condition=IN&filters[0].Value=CT,NoBilling`
      )
      .then((res) => res.data)
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

    setReasonsOptions(
      reasons?.map(({ id, name, type }) => ({
        label: name,
        value: id,
        type,
      }))
    );

    setReasonNoBillingOptions(
      reasons
        ?.filter(({ type }) => type === 'NoBilling')
        .map(({ id, name, type }) => ({
          label: name,
          value: id,
          type,
        }))
    );
  };

  const fetchTaskAcceptanceDeadline = () =>
    api
      .get(`/Settings?id=1`)
      .then((res) => {
        taskAcceptanceDeadline = res.data.taskAcceptanceDeadline;
      })
      .catch((error) => {
        Utils.logError(error);
        message.error('Oops. Algo deu errado!');
      });

  const getCurrentDate = () => scheduleDate;

  const handleTrainingCenterChange = async (value) => {
    try {
      // Encontrar o centro de treinamento na lista de opções.
      const selectedTrainingCenter = trainingCenterOptions.find((tc) => tc.id === value);
      if (!selectedTrainingCenter) {
        message.error('Centro de treinamento não encontrado.');
        return;
      }

      // Atualizar o estado com o novo centro de treinamento.
      setSelectedTrainingCenterId(value);
      setTrainingCenter(selectedTrainingCenter);

      // Atualizar os dados no localStorage se necessário.
      const scheduleData = {
        ...JSON.parse(localStorage.getItem('conecta__scheduleData') || '{}'),
        trainingCenterId: selectedTrainingCenter.id,
        trainingCenter: selectedTrainingCenter.commercialName,
        maxCapacityPerDay: selectedTrainingCenter.maxCapacityPerDay,
      };
      localStorage.setItem('conecta__scheduleData', JSON.stringify(scheduleData));

      // Refazer buscas e cálculos relacionados ao novo centro.
      const slots = await fetchSlots(selectedTrainingCenter);
      callSlotGeneration(selectedTrainingCenter, null, null, null, slots);
      await fetchTasks(selectedTrainingCenter);
      fetchInstructors(); // Atualiza instrutores se eles estiverem ligados ao centro.

      // Re-renderiza o formulário ou componentes relacionados se necessário.
    } catch (error) {
      message.error('Erro ao processar a mudança de centro de treinamento.');
      message.error(error);
    }
  };

  const onContentReady = async (e) => {
    const { component } = e;
    const view = component.option('currentView');
    const date = moment(component.option('currentDate')).format('YYYY-MM-DD');

    const currentViewChanged = view !== scheduleView;
    const currentDateChanged = date !== scheduleDate;
    const filterChanged = filterCount !== filterCountNew;

    filterCount = filterCountNew;
    scheduleView = view;
    scheduleDate = date;

    let toolbar = component.option('toolbar');
    toolbar = [
      {
        location: 'before',
        defaultElement: 'dateNavigator',
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Resumo',
          onClick: () => {
            resumePopupRef.current.instance.show();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Overview',
          onClick: () => {
            overviewPopupRef.current.instance.show();
          },
        },
      },
      {
        location: 'before',
        widget: 'dxTagBox',
        options: {
          displayExpr: 'label',
          keyExpr: 'value',
          width: 300,
          items: stagesOptions,
          maxDisplayedTags: 1,
          value: stagesValue,
          valueExpr: 'value',
          showSelectionControls: true,
          onFocusOut: (filter) => filterAppointments(filter),
        },
      },
      {
        location: 'before',
        widget: 'dxSelectBox',
        key: selectedTrainingCenterId,
        options: {
          displayExpr: 'commercialName',
          keyExpr: 'id',
          width: 300,
          dataSource: trainingCenterOptions,
          valueExpr: 'id',
          value: selectedTrainingCenterId,
          onValueChanged: ({ value }) => handleTrainingCenterChange(value),
        },
      },
    ];

    if (firstOpen === true && appointments.length > 0) {
      firstOpen = false;
      component.option('toolbar', toolbar);
    }

    if (
      currentViewChanged ||
      currentDateChanged ||
      filterChanged ||
      selectedTrainingCenterId !== lastSelectedTrainingCenterId
    ) {
      component.option('toolbar', toolbar);
      setLastSelectedTrainingCenterId(selectedTrainingCenterId);
    }

    if (currentViewChanged || currentDateChanged) {
      fetchTasks(trainingCenter, component.getStartViewDate(), component.getEndViewDate());
      callSlotGeneration(
        trainingCenter,
        date,
        component.getStartViewDate(),
        component.getEndViewDate(),
        slots
      );
    }

    if (trainingOpened === false && urlParams?.action === 'OpenTraining') {
      trainingOpened = true;
      const tasks = await api
        .get(
          `/Task?filters[0].Field=Id&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${parseInt(
            urlParams.taskId,
            10
          )}`
        )
        .then((res) => res.data);

      if (tasks?.length > 0) {
        const [task] = tasks;
        const [quote] = await fetchQuotes(task.dealId.toString());

        const taskMapped = { ...quote, ...task };
        component.showAppointmentPopup(taskMapped);
      }
    }
  };

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'ScheduleTrainingCenter') {
          fields.forEach(({ name, access, isRequired, isADM }) => {
            permissions[name] = { access, isRequired, isADM };
          });
        }
      }
    );

    setFieldsPermissions(permissions);
    fetchTrainingCenter();
    fetchBlockedDays();
  }, []);

  useEffect(() => {
    fetchTaskAcceptanceDeadline();
    fetchInstructors();
    fetchDrivers();
    fetchTrainings();
    fetchStages();
    fetchReasons();
  }, []);

  // Reseta o carregamento da tela quando o usuário sai da tela
  useEffect(
    () => () => {
      firstOpen = true;
    },
    [location]
  );

  if (loading || !ctResources || !ctResourcesSlots) {
    return (
      <Row gutter={[24]}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '72vh',
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 56,
                  textAlign: 'center',
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
    );
  }

  if (!loading && ctResources && ctResourcesSlots) {
    return (
      <Row gutter={[12]}>
        <Col span={24}>
          <div className="changeVisibility">
            <Switch
              checkedChildren={<CalendarOutlined />}
              unCheckedChildren={<UnorderedListOutlined />}
              defaultChecked
              onClick={() => {
                setChangeVisibility(!changeVisibility);
              }}
            />
          </div>
          <List
            id="tasks-list"
            style={{ marginTop: 35 }}
            visible={changeVisibility}
            dataSource={appointments}
            height="calc(100vh - 90px)"
            searchExpr={['text', 'classId', 'quoteTitle', 'stage.name', 'startDate']}
            searchEnabled={true}
            itemRender={renderItem}
            onItemClick={onListItemClick}
          />
          <CustomDropdown
            type="TrainingCenter"
            sourceId={parseInt(
              JSON.parse(localStorage.getItem('conecta__userData'))?.companyId,
              10
            )}
            updateBlockedDays={fetchBlockedDays}
            workDays={trainingCenter?.workDays}
            breakTime={{
              breakStart: trainingCenter?.breakStart,
              breakFinish: trainingCenter?.breakFinish,
            }}
            blockedDates={blockedDates}
            dataSource={appointments}
            visible={changeVisibility}
          />
          <OverviewPopup
            overviewPopupRef={overviewPopupRef}
            overviewList={overview}
            totalStudents={trainingCenter?.maxCapacityPerDay}
            getCurrentDate={getCurrentDate}
          />
          <ResumePopup
            resumePopupRef={resumePopupRef}
            resumeList={resume}
            getCurrentDate={getCurrentDate}
            schedulerRef={schedulerRef}
            ctName={trainingCenter?.name}
          />
          <Scheduler
            style={
              !changeVisibility
                ? null
                : {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: 0,
                    width: 0,
                    visibility: 'hidden',
                  }
            }
            ref={schedulerRef}
            disabled={loadingSchedule}
            id="scheduleTrainingCenter"
            views={views}
            groups={groups}
            groupByDate
            dataSource={appointments.filter(({ stage: { id } }) => stagesValue?.includes(id))}
            defaultCurrentView={
              localStorage.getItem('conecta__currentView')
                ? localStorage.getItem('conecta__currentView')
                : currentView
            }
            defaultCurrentDate={
              localStorage.getItem('conecta__currentDate')
                ? new Date(localStorage.getItem('conecta__currentDate'))
                : currentDate
            }
            startDayHour={8}
            endDayHour={18}
            showAllDayPanel={false}
            firstDayOfWeek={1}
            maxAppointmentsPerCell={1}
            dataCellRender={renderDataCell}
            dateCellRender={renderDateCell}
            resourceCellRender={resourceCellRender}
            onCurrentViewChange={onCurrentViewChange}
            onCurrentDateChange={onCurrentDateChange}
            onAppointmentFormOpening={onAppointmentFormOpening}
            onAppointmentUpdating={onAppointmentUpdating}
            appointmentComponent={appointmentRender}
            onContentReady={onContentReady}
            crossScrollingEnabled
            showCurrentTimeIndicator={false}
            editing={{
              allowResizing: false,
              allowDeleting: false,
            }}
            onCellClick={(e) => {
              e.cancel = true;
            }}
          >
            <AppointmentDragging
              group={draggingGroupName}
              onDragStart={(e) => {
                e.cancel = true;
              }}
            />
            <Resource
              fieldExpr="resourceId"
              allowMultiple={false}
              dataSource={ctResources}
              label="Recurso"
            />
          </Scheduler>
        </Col>
        <ReasonModal
          isModalOpen={isReasonModalOpen}
          setModalOpen={setReasonModalOpen}
          reasonsOptions={reasonsOptions}
          currentOpenTask={currentOpenTask}
          event={event}
          eventSource="CT"
          onOk={onModalOk}
        />
        <ModalUploadFile
          isOpen={isUploadFileModalOpen}
          setIsOpen={setUploadFileModalOpen}
          loading={loading}
          currentOpenTask={currentOpenTask}
          updateTask={onAppointmentUpdating}
          type={uploadFileType}
        />
      </Row>
    );
  }
}

const ScheduleTrainingCenter = memo(ScheduleTrainingCenterComponent);
export default ScheduleTrainingCenter;
