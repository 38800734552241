/* eslint-disable no-unused-vars */
import { message } from 'antd';

import Utils from '../../../Assets/Scripts/Utils';
import ScheduleUtils from '../../../Components/Schedule/ScheduleUtils';
import { api } from '../../../Services/axiosService';

const mountProductName = (product) => {
  const customProductList = ScheduleUtils.getCustomProductList();
  const isCustomProduct = customProductList.includes(product?.id);

  if (isCustomProduct) {
    return product.complement ? product.complement : product.name;
  }

  return product.name;
};

export const fetchTasks = async (filters = null) => {
  try {
    const url = '/Task/TaskList';
    const response = await api.post(url, filters);
    const tasks = response.data;

    const notSelectableStages = [
      1, // 1 = Aguardando
      5, // 5 = Concluido
      6, // 6 = Reagendar
      11, // 11 = Distrato
      3, // 3 = Confirmado
      4, // 4 = Negado
      6, // 6 = Reagendar
      10, // 10 = Pré-Reservado
      12, // 12 = Reservado
      13, // 13 = Reservado sem confirmação
    ];

    const mappedTasks = tasks
      .map((task, index) => {
        // const isStageSelectable = !notSelectableStages.includes(task.stage?.id);
        // const isCertificateModelFilled = task.certificateModel;
        // const isStudentListFilled =
        //   task.studentList?.length > 0 && task.queryStudentList?.length > 0;

        const isStageSelectable = true;
        const isCertificateModelFilled = true;
        const isStudentListFilled = true;

        const studentList = task.queryStudentList?.map((student) => ({
          ...student,
          key: student.id,
          readyGenerateCertificate:
            isStageSelectable && isCertificateModelFilled && isStudentListFilled,
        }));

        return {
          ...task,
          tablePageIndex: index,
          key: task.id,
          name: mountProductName(task.product),
          readyGenerateCertificate:
            isStageSelectable && isCertificateModelFilled && isStudentListFilled,
          children: studentList,
        };
      })
      .sort((a, b) => a.id - b.id);

    return mappedTasks;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Treinamentos!');
    return [];
  }
};

export const fetchSettings = async () => {
  try {
    const response = await api.get(`/Settings?id=1`);
    return Utils.decryptSettings(response.data);
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar as Configurações!');
    return [];
  }
};

export const fetchStages = async () => {
  try {
    const response = await api.get(`/Stage`);
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data.map((stage) => ({
        ...stage,
        label: stage.name,
        value: stage.id,
      })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Estágios!');
    return [];
  }
};

export const fetchProduct = async () => {
  try {
    const response = await api.get(`/Product`);
    return [
      ...response.data.map((product) => ({
        ...product,
        label: product.name,
        value: product.id,
      })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Estágios!');
    return [];
  }
};

export const fetchTrainingCenters = async () => {
  try {
    const response = await api.get(
      `/TrainingCenter?filters[0].Field=CompanyType&filters[0].Condition=EQUAL&filters[0].Value=Centro de Treinamento`
    );
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data
        .filter(
          (tc) =>
            tc.ctQtySlots > 0 ||
            tc.inCompanyQtySlots > 0 ||
            tc.eadQtySlots > 0 ||
            tc.serviceQtySlots > 0
        )
        .sort((a, b) => b.ctQtyslots - a.ctQtyslots)
        .map((tc) => ({ ...tc, value: tc.id, label: tc.commercialName })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Centros de Treinamento!');
    return [];
  }
};

export const fetchTrainings = async () => {
  try {
    const response = await api.get('/Trainings');
    return [
      { label: 'Selecionar Todos', value: 'selectAll' },
      ...response.data.map((trainings) => ({ label: trainings.name, value: trainings.id })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao buscar os Grupos de Treinamento!');
    return [];
  }
};
