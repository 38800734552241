/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../../Services/axiosService';

export default function CertificateSettingsModal({
  onCancel,
  isCertificateModalOpen,
  setIsOpen,
  certidicateModelOptions,
  taskList,
  selectedRowKeys,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (values) => {
    try {
      if (selectedRowKeys.length > 0) {
        setIsLoading(true);

        console.log(values);

        const selectedTaskList = taskList.filter(({ id }) => selectedRowKeys.includes(id));

        for (let index = 0; index < selectedTaskList.length; index += 1) {
          const newTask = selectedTaskList[index];
          const oldTask = JSON.parse(JSON.stringify(selectedTaskList[index]));

          newTask.certificateSayings = values.certificateSayings;

          // Atualiza Treinamento
          await api.put('/Task/V2/UpdateTaskScheduling', newTask);

          // Gera Atividade de Upload de Arquivo
          await TaskCommentFunctions.addTaskCommentUploadFile(
            newTask,
            'CertificateModelUploaded',
            'Certificates'
          );
          await TaskCommentFunctions.addTaskCommentHistory(newTask, oldTask, 'Certificates');
        }
      }
      message.success('Tarefas atualizadas com sucesso!');
      form.resetFields();
      setIsOpen(false);
    } catch (error) {
      Utils.error('Erro ao atualizar tarefas:', error);
      message.error('Erro ao atualizar tarefas.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      name="certificateSettings"
      className="schedule-modal"
      open={isCertificateModalOpen}
      confirmLoading={isLoading}
      closable={false}
      maskClosable={false}
      width={1000}
      footer={[
        <Button
          key="back"
          onClick={() => {
            form.resetFields();
            if (onCancel) onCancel();
            setIsOpen(false);
          }}
        >
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={() => form.submit()}>
          Confirmar
        </Button>,
      ]}
    >
      <h2>Configuração Certificado no Treinamento</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={submit}
        style={{ maxWidth: '100%', margin: '0 auto' }}
      >
        {/* <Form.Item
          label="Modelo Certificado"
          name="certificateModel"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
          fieldId="name"
        >
          <Select
            placeholder="Selecione"
            optionFilterProp="label"
            dropdownStyle={{ borderRadius: 16 }}
            options={certidicateModelOptions.map((option) => ({
              label: option.label,
              value: JSON.stringify(option),
            }))}
          />
        </Form.Item> */}
        {/* <Form.Item
          label="Produto"
          name="certificateProduct"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item> */}

        {/* <Form.List name="programContentList">
          {(fields, { add, remove }) => (
            <>
              <Button
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  width: 400,
                }}
                type="dashed"
                onClick={() => {
                  add();
                }}
                block
                icon={<PlusOutlined />}
              >
                Adicionar Conteúdo Programático
              </Button>

              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={[24]} key={key}>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'module']}
                      rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    >
                      <Input placeholder="Módulo" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'subject']}
                      rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    >
                      <Input placeholder="Assunto" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'theoreticalGoals']}
                      rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    >
                      <Input placeholder="Objetivos Teóricos" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...restField}
                      name={[name, 'practicalGoals']}
                      rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    >
                      <Input placeholder="Objetivos Práticos" />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <DeleteOutlined
                      style={{ color: 'red', float: 'right' }}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List> */}

        <Form.Item
          label="Dizeres"
          name="certificateSayings"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Adicione os dizeres"
            style={{ borderRadius: '10px' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
